<template>
  <div class="mt-3">
    <v-row class="mt-5 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-message-alert</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          ANFRAGEN GESUNDHEITSAMT
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-xl px-4" dark>
          <v-row>
            <v-col cols="12">
              <v-tabs fixed-tabs>
                <v-tab :disabled="user.ga.anfragen.open.length == 0">
                  <v-badge
                    color="grey"
                    :content="user.ga.anfragen.open.length"
                    :value="user.ga.anfragen.open.length"
                  >
                    OFFEN
                  </v-badge>
                </v-tab>
                <v-tab :disabled="user.ga.anfragen.closed.length == 0">
                  <v-badge
                    color="grey"
                    :content="user.ga.anfragen.closed.length"
                    :value="user.ga.anfragen.closed.length"
                  >
                    BEANTWORTET
                  </v-badge>
                </v-tab>
                <v-tab-item>
                  <v-row justify="center">
                    <v-col
                      cols="11"
                      v-for="(ereignis, index) in user.ga.anfragen.open"
                      :key="index"
                    >
                      <v-card
                        class="rounded-xl pa-4"
                        :color="template.colors.blocks"
                        :style="`color: ${template.colors.block_text};`"
                      >
                        <v-row>
                          <v-col cols="auto" align-self="center">
                            <v-icon large :color="template.colors.block_text">
                              mdi-message-text-clock
                            </v-icon>
                          </v-col>
                          <v-col>
                            <h2>{{ getSportstaette(ereignis.locationId) }}</h2>

                            <span class="font-weight-light">Start:</span>
                            {{ parseTimestamp(ereignis.startDate) }}
                            <br />
                            <span class="font-weight-light">Ende:</span>
                            {{ parseTimestamp(ereignis.endDate) }}
                            <hr />
                            <span>
                              <b>Anfragedetails:</b>
                              {{ ereignis.details }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-btn
                            class="ml-auto"
                            text
                            rounded
                            :color="template.colors.success"
                            @click="acceptRequest(ereignis)"
                            :loading="loading == ereignis.eventToken"
                          >
                            <v-icon class="mr-1">
                              {{
                                ereignis.status
                                  ? ereignis.status == 'sendData'
                                    ? 'mdi-timer-sand'
                                    : ereignis.status == 'SUCCESS'
                                    ? 'mdi-check-bold'
                                    : 'mdi-alert-circle'
                                  : 'mdi-check-circle'
                              }}
                            </v-icon>
                            {{
                              ereignis.status
                                ? ereignis.status == 'sendData'
                                  ? 'Übertragung läuft...'
                                  : ereignis.status == 'SUCCESS'
                                  ? 'Daten erfolgreich übermittelt'
                                  : 'FEHLER'
                                : 'Zustimmen'
                            }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row justify="center">
                    <v-col
                      cols="11"
                      v-for="(ereignis, index) in user.ga.anfragen.closed"
                      :key="index"
                    >
                      <v-card
                        class="rounded-xl pa-4"
                        :color="template.colors.success"
                        :style="`color: ${template.colors.success_text};`"
                      >
                        <v-row>
                          <v-col cols="auto" align-self="center">
                            <v-icon large :color="template.colors.success_text">
                              mdi-check-bold
                            </v-icon>
                          </v-col>
                          <v-col>
                            <h2>{{ getSportstaette(ereignis.locationId) }}</h2>

                            <v-chip
                              small
                              class="mr-2 my-2"
                              :color="template.colors.blocks"
                              :light="!template.isDark(template.colors.blocks)"
                              :dark="template.isDark(template.colors.blocks)"
                            >
                              <span class="font-weight-bold mr-2">Start:</span>
                              {{ parseTimestamp(ereignis.startDate) }}
                            </v-chip>

                            <v-chip
                              small
                              class="ml-2 my-2"
                              :color="template.colors.blocks"
                              :light="!template.isDark(template.colors.blocks)"
                              :dark="template.isDark(template.colors.blocks)"
                            >
                              <span class="font-weight-bold mr-2">Ende:</span>
                              {{ parseTimestamp(ereignis.endDate) }}
                            </v-chip>
                            <br />
                            <span v-if="ereignis.details">
                              <b>Anfragedetails:</b>
                              {{ ereignis.details }}
                            </span>
                            <span v-if="ereignis.ga">
                              <b>Anfrage wurde verschickt von:</b>
                              {{ ereignis.ga }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-chip
                            outlined
                            :color="template.colors.blocks"
                            :light="!template.isDark(template.colors.blocks)"
                            :dark="template.isDark(template.colors.blocks)"
                          >
                            <b class="mr-2">Genehmigt von:</b>
                            {{ ereignis.name }}
                          </v-chip>
                          <v-btn
                            class="ml-auto"
                            text
                            rounded
                            :color="template.colors.success"
                            :loading="loading == ereignis.eventToken"
                          >
                            <v-icon class="mr-1">
                              {{
                                ereignis.status
                                  ? ereignis.status == 'sendData'
                                    ? 'mdi-timer-sand'
                                    : ereignis.status == 'SUCCESS'
                                    ? 'mdi-check-bold'
                                    : 'mdi-alert-circle'
                                  : 'mdi-check-circle'
                              }}
                            </v-icon>
                            {{
                              ereignis.status
                                ? ereignis.status == 'sendData'
                                  ? 'Übertragung läuft...'
                                  : ereignis.status == 'SUCCESS'
                                  ? 'Daten erfolgreich übermittelt'
                                  : 'FEHLER'
                                : 'Zustimmen'
                            }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'

export default {
  name: 'Anfragen Gesundheitsamt',
  data() {
    return {
      filter: {
        datum: '',
        vondatum: '',
        today: '',
        startzeit: '',
        endzeit: '',
        vorname: '',
        name: '',
        besuchsart: '',
        veranstaltungsuebergreifend: '',
      },
      filter_selection: 'person',
      anwesenheitsliste: [],
      anwesenheitsliste_zuschauer: [],
      anwesenheitsliste_andere: [],
      generating: false,
      loading: '',
      bisdatum: false,
      vondatum: false,
      endedatum: false,
      earliest_date: '',
      ereignisse_open: [],
      ereignisse_closed: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    if (this.user.data.rolle != 'Vorstand') {
      router.push('/')
    }
  },
  methods: {
    acceptRequest(ereignis) {
      this.loading = ereignis.eventToken
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Ereignisse')
        .doc(ereignis.eventToken)
        .update({
          status: 'sendData',
          sportstaette: this.getSportstaette(ereignis.locationId),
          name: this.user.displayName,
        })
        .then(() => {
          this.loading = ''
        })
    },
    parseTimestamp(tmstmp) {
      const datetime = tmstmp.replace('.000Z', '').split('T')
      var date = new Zeit(datetime[0], datetime[1])
      date.ZeitObjectFromIRIS()
      return (
        this.parseDate(date.getDatum()) +
        ' ' +
        this.parseTime(date.getUhrzeit()) +
        ' Uhr'
      )
    },
    getSportstaette(id) {
      const index = id.split('-')[1]
      return this.user.data.sportstaetten[index].name
    },
    addNull(number) {
      if (number < 10) {
        return '0' + number
      }
      return number
    },
    parseTime(time) {
      const zeit = time.replace('.000', '').split(':')
      return zeit[0] + ':' + zeit[1]
    },
    parseDate(date) {
      if (date) {
        var datum = date.split('-')
        if (date == this.timer.datum) {
          return 'Heute'
        } else {
          return datum[2] + '.' + datum[1] + '.' + datum[0]
        }
      } else {
        return ''
      }
    },
    parseDate1(date) {
      if (date) {
        var datum = date.split('-')
        return datum[2] + '.' + datum[1] + '.' + datum[0]
      } else {
        return ''
      }
    },
  },
}
</script>
